<template>
	<w-dialog
		max-width="50vw"
		:title="
			$t('application.subscription_title', {
				application: application ? application.title : ''
			})
		"
		:value="open"
		@close="closeDialog()"
	>
		<v-container>
			<w-layout row wrap>
				<w-flex xs12>
					<template v-if="step === 1">
						<w-form v-model="isFormValid">
							<w-select
								v-model="formData.endpoint"
								:items="endpoints"
								item-text="text"
								item-value="value"
								:label="$t('application.apps.beeye.endpoint')"
								required
							/>
							<w-text-input v-model.trim="formData.token" :label="$t('application.apps.beeye.token')" required />
						</w-form>
					</template>
					<template v-else-if="step === 2">
						<w-form v-model="isFormValid">
							<w-select
								v-model="formData.endpoint"
								:items="endpoints"
								item-text="text"
								item-value="value"
								label="$t('application.apps.beeye.endpoint')"
								readonly
							/>
							<w-text-input v-model="formData.token" :label="$t('application.apps.beeye.token')" readonly />
							<w-select
								v-model="formData.direction"
								:label="$t('application.apps.beeye.direction')"
								:items="directions"
								item-text="name"
								item-value="id"
								required
							/>
						</w-form>
					</template>
				</w-flex>
			</w-layout>
		</v-container>
		<template v-slot:actions>
			<v-layout row wrap>
				<v-spacer></v-spacer>
				<template v-if="step == 1">
					<w-btn :disabled="!isFormValid" flat :loading="testLoading" @click="goToStep2()">{{ $t('actions.test') }} </w-btn>
				</template>
				<template v-else-if="step === 2">
					<w-btn-save :disabled="!isFormValid" :loading="saveLoading" @click="save()" />
				</template>
			</v-layout>
		</template>
	</w-dialog>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'BeeyeSubscriptionDialog',
	mixins: [ApplicationsModuleGuard],
	props: {
		open: {
			required: true,
			type: Boolean
		},
		value: {
			default: null,
			required: false,
			type: Object
		}
	},
	data: function () {
		return {
			directions: [],
			formData: {
				direction: null,
				endpoint: null,
				token: null
			},
			isFormValid: false,
			testLoading: false,
			saveLoading: false,
			step: 1
		}
	},
	computed: {
		application: function () {
			return this.value
		},
		endpoints: function () {
			let result = []

			if (this.application?.params_accounting_firm) {
				result = this.application.params_accounting_firm.endpoints
			}

			return result
		}
	},
	methods: {
		checkToken: function () {
			return this.service.checkBeeyeToken(this.accountingFirmId, this.formData.endpoint, this.formData.token)
		},
		closeDialog: function () {
			this.formData.direction = null
			this.formData.endpoint = null
			this.formData.token = null
			this.step = 1
			this.testLoading = false
			this.saveLoading = false
			this.$emit('close')
		},
		goToStep2: function () {
			this.testLoading = true
			this.checkToken()
				.then(() => {
					return this.loadDirections()
				})
				.then(() => {
					this.step = 2
				})
				.catch(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_WARNING, this.$t('application.apps.beeye.errors.invalid_credentials'))
				})
				.finally(() => {
					this.testLoading = false
				})
		},
		loadDirections: function () {
			return this.service.getBeeyeDirections(this.accountingFirmId, this.formData.endpoint, this.formData.token).then(directions => {
				this.directions = directions
			})
		},
		save: function () {
			this.saveLoading = true
			this.service
				.createSubscription(this.accountingFirmId, this.application.id, {
					uri: this.formData.endpoint,
					token: this.formData.token,
					direction: this.formData.direction
				})
				.then(result => {
					this.eventBus.emit(this.events.APPLICATION_SUBSCRIPTION_CREATED, result)
					this.closeDialog()
				})
				.catch(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_WARNING, this.$t('application.apps.beeye.errors.add_fail'))
				})
		}
	}
}
</script>
